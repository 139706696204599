import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b8c5248"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_busy_page = _resolveComponent("busy-page")!
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_bar = _resolveComponent("footer-bar")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "mh-100" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_theme_provider, {
        "with-background": "",
        class: "mh-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_layout, { class: "mh-100" }, {
            default: _withCtx(() => [
              (_ctx.isBusy)
                ? (_openBlock(), _createBlock(_component_busy_page, { key: 0 }))
                : (_openBlock(), _createBlock(_component_v_main, {
                    key: 1,
                    class: "bg-app-gray"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", null, [
                        _createVNode(_component_header_bar, { onRender: _ctx.rerenderChildComponent }, null, 8, ["onRender"]),
                        _createVNode(_component_nav_bar)
                      ], 512), [
                        [_vShow, _ctx.key > 0 && _ctx.isAuthenticated && !_ctx.isApiDoc && !_ctx.isOpenApiDoc]
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["content-wrapper", { 'pb-50': _ctx.isAuthenticated && !_ctx.isApiDoc && !_ctx.isOpenApiDoc }])
                      }, [
                        (!_ctx.isAuthenticated || !_ctx.isLogout || _ctx.key > 0)
                          ? (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
                          : _createCommentVNode("", true)
                      ], 2),
                      _withDirectives(_createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_footer_bar)
                      ], 512), [
                        [_vShow, _ctx.key > 0 && _ctx.isAuthenticated && !_ctx.isApiDoc && !_ctx.isOpenApiDoc]
                      ]),
                      (_ctx.isLogout)
                        ? (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_loading)
    ]),
    _: 1
  }))
}